module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"OZ-Bud - Usługi wykończeń mieszkań i domów","short_name":"OZ-BUD","description":"Nasza firma wykończeniowa oferuje kompleksowe usługi remontowe, włączając malowanie, montaż podłóg oraz instalacje sanitarno-elektryczne, zapewniając profesjonalizm, pasję i transparentność, aby Twoje mieszkanie stało się wymarzoną przestrzenią.","start_url":"/","background_color":"#fff","display":"standalone","icons":[{"src":"/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
