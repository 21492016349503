exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-adaptacja-poddaszy-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=C:/Users/micha/Desktop/OZNEW/oz/content/projects/adaptacja-poddaszy/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-adaptacja-poddaszy-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-beton-architektoniczny-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=C:/Users/micha/Desktop/OZNEW/oz/content/projects/beton-architektoniczny/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-beton-architektoniczny-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-glazurnictwo-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=C:/Users/micha/Desktop/OZNEW/oz/content/projects/glazurnictwo/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-glazurnictwo-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-hydraulika-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=C:/Users/micha/Desktop/OZNEW/oz/content/projects/hydraulika/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-hydraulika-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-instalacje-elektryczne-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=C:/Users/micha/Desktop/OZNEW/oz/content/projects/instalacje-elektryczne/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-instalacje-elektryczne-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-lazienka-a-do-z-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=C:/Users/micha/Desktop/OZNEW/oz/content/projects/lazienka-a-do-z/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-lazienka-a-do-z-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-malowanie-pokoi-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=C:/Users/micha/Desktop/OZNEW/oz/content/projects/malowanie-pokoi/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-malowanie-pokoi-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-remonty-wykonczenia-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=C:/Users/micha/Desktop/OZNEW/oz/content/projects/remonty-wykonczenia/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-remonty-wykonczenia-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-sucha-zabudowa-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/project-query.tsx?__contentFilePath=C:/Users/micha/Desktop/OZNEW/oz/content/projects/sucha-zabudowa/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-project-query-tsx-content-file-path-content-projects-sucha-zabudowa-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emilia-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emilia-core-src-templates-projects-query-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */)
}

